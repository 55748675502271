<template>
    <div class="primary">
      <v-row class="pa-12 mt-12">
        <h1 class="secondary--text">الأخبار</h1>
        <v-spacer />
        <v-btn outlined @click="$router.go(-1)" class="mr-12 btn">
          <h4>رجوع</h4>
          <v-icon>fi fi-rr-arrow-small-left</v-icon>
        </v-btn>
      </v-row>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" v-for="(dr, index) in news" :key="index"> 
            <v-hover v-slot="{ hover }">
            <v-card :to="'news/'+dr.id" style="border-radius: 40px" outlined height="500" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
              <v-container>
                <v-card flat>
                  <div class="text-start mt-2" style="font-size: 20px; font-weight: bold; color: #27354A; border-radius: 40px; border: solid 1px #E0E0E0">
                    <v-img :src="$url+dr.imageUrl" height="300" style="border-radius: 40px" >
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out grey darken-4 v-card--reveal text-h2 white--text"
                          style="height: 100%;"
                        >
                          <!-- <v-btn color="primary" class="btn" :to="'news/'+dr.id">
                            <h2>
                              اضغط هنا لعرض المزيد...
                            </h2>
                          </v-btn> -->
                        </div>
                      </v-expand-transition>
                    </v-img>
                    
                  </div>
                  <br />
                  <h3 class="mr-4" :class="hover ? 'primary--text' : 'black--text'">{{ dr.name }}</h3>
                  <div class="text-start mt-2 mx-3" style="font-size: 20px; font-weight: normal; color: #27354A">
                    {{ dr.shortDescription }}
                    <br/>
                    <!-- <a :href="'news/'+dr.id">اضغط هنا لعرض المزيد...</a> -->
                  </div>
                </v-card>
                
              </v-container>
              <!-- <v-btn :to="'news/'+dr.id" class="btn" color="primary">عرض التفاصيل</v-btn> -->
            </v-card>
          </v-hover>
          </v-col>
        </v-row>
      </v-container>
      <v-row v-if="loading" class="text-center mx-auto ma-12">
        <!-- <v-progress-circular indeterminate></v-progress-circular> -->
        <loading v-if="loading" />
      </v-row>
      <!-- <v-row v-if="allNewsLoaded && !loading" class="text-center ma-12">
        <h1 class="mx-auto">لقد وصلت إلى نهاية القائمة.</h1>
      </v-row> -->
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        news: [],
        pageNumber: 1,
        pageSize: 20,
        allNewsLoaded: false,
      }
    },
  
    created() {
      this.getNews();
    },
  
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
  
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  
    methods: {
      async getNews() {
        if (this.loading || this.allNewsLoaded) return;
  
        this.loading = true;
        try {
          const response = await this.$http.get(`Post?PageIndex=${this.pageNumber}&PageSize=${this.pageSize}`);
          if (response.data && response.data.result.length > 0) {
            this.news.push(...response.data.result);
            this.pageNumber++;
          } else {
            this.allNewsLoaded = true;
          }
        } catch (err) {
          console.error("Error fetching news:", err);
        } finally {
          this.loading = false;
        }
      },
  
      handleScroll() {
        const bottomOfWindow = window.innerHeight + window.scrollY >= document.body.offsetHeight - 20;
        if (bottomOfWindow) {
          this.getNews();
        }
      },
    },
  }
  </script>
  
  <style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>